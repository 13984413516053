@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sedan+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sixtyfour&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jersey+15&display=swap');


body {
    background: black;
}


h1 {
    font-family: 'Sixtyfour', sans-serif;
    font-weight: 400;
    font-style: normal;
}


p, h2 {
    font-family: "Jersey 15", sans-serif;
    font-weight: 400;
    font-style: normal;
}

