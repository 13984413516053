

.swiper {
  width: 80%;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .swiper {
    width: 100%;
  }
}


.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 400px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
